import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DiamondSearchedParamsComponent } from 'src/app/pages/diamond-search/diamond-searched-params/diamond-searched-params.component';
import { IonicSelectableComponent } from 'ionic-selectable';
import { PipesModule } from 'src/app/pipes/pipes.module';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [DiamondSearchedParamsComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    IonicSelectableComponent,
    PipesModule,
  ],
  exports: [DiamondSearchedParamsComponent],
})
export class DiamondSearchedParamsComponentModule {}
