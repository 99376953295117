import { NgModule } from '@angular/core';

import { TimeAgoPipe } from './time-ago/time-ago.pipe';
import { SortCodesPipe } from './sort-code/sort-codes.pipe';
import { SortColorPipe } from './sort-color/sort-color.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
@NgModule({
  declarations: [TimeAgoPipe, SortCodesPipe, SortColorPipe, CapitalizePipe],
  imports: [],
  exports: [TimeAgoPipe, SortCodesPipe, SortColorPipe, CapitalizePipe],
})
export class PipesModule {}
