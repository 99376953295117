import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-diamond-searched-params',
  templateUrl: './diamond-searched-params.component.html',
  styleUrls: ['./diamond-searched-params.component.scss'],
})
export class DiamondSearchedParamsComponent implements OnInit {
  searchPara: any = {};
  constructor(
    private navParams: NavParams
  ) { }

  async ngOnInit() {
    this.searchPara = await this.navParams.get('searchParameter');
    debugger;
    // isRefined
  }

}
