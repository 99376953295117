import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortColor',
})
export class SortColorPipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    let newVal = value?.trim();
    if (!value) {
      newVal = '-';
    }
    const abbreviation = this.convertToAbbreviation(newVal);
    return abbreviation;
    // const isLong = /\s/.test(newVal);

    // if (isLong) {
    //   let result = newVal[0];
    //   let isSpace = false;
    //   for (let i = 0; i < newVal.length; i++) {
    //     if (newVal[i] === " " || newVal[i] === ",") {
    //       isSpace = true;
    //     }
    //     if (isSpace && newVal[i] !== " ") {
    //       result += " " + newVal[i];
    //       isSpace = false;
    //     }
    //     if (newVal[i] === ",") {
    //       result += newVal[i]
    //     }
    //   }
    //   newVal = result;
    // }
    // return newVal;
  }

  convertToAbbreviation(str) {
    const words = str.split(' ');
    let abbreviation = '';

    words.forEach((word, index) => {
      abbreviation += word.charAt(0).toUpperCase();

      if (word.includes(',')) {
        abbreviation += ', ';
      } else if (index < words.length - 1) {
        abbreviation += ' ';
      }
    });

    return abbreviation;
  }
}
